const Footer = () => {
  return (
    <div className="footerlast">
      <div className="copyright">
        <hr style={{ margin: 0 }} />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 mb-2">
              <div
                className="copyright-info pt-3 pb-3"
                style={{ alignItems: "center" }}
              >
                <div style={{ float: "right" }}>
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    className="mr-2" rel="noreferrer"
                  >
                    <i
                      className="fab fa-twitter footer_icon"
                      aria-hidden="true"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    className="mr-2" rel="noreferrer"
                  >
                    <i
                      className="fab fa-facebook footer_icon"
                      aria-hidden="true"
                    />
                  </a>
                </div>
                <span>
                  Copyright &copy;
                  <script>document.write(new Date().getFullYear())</script>{" "}
                  MicroCats
                </span>
              </div>
            </div>
          </div>
          <div
            id="back-to-top"
            data-spy="affix"
            data-offset-top="10"
            className="back-to-top position-fixed"
          >
            <button className="btn btn-primary" title="Back to Top">
              <i className="fa fa-angle-double-up"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink, useLocation } from "react-router-dom";

import React, { Fragment, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import "./header.css";

import { toast } from "react-toastify";
import ConnectWallet from "../../views/ConnectWallet/ConnectWallet";

const Header = () => {
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [activeMenu, setMenu] = useState("Home");
  const { account, deactivate } = useWeb3React();
  const location = useLocation();

  const clearLocal = () => {
    window.localStorage.clear();
    localStorage.clear();
    sessionStorage.clear();
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/shop":
        setMenu("Shop");
        break;
      case "/collections":
        setMenu("Collections");
        break;
      case "/about-us":
        setMenu("About");
        break;
      case "/createNFT":
        setMenu("create");
        break;
      case "/marketplace":
        setMenu("Marketplace");
        break;
      case "/levelup-rankup":
        setMenu("levelup");
        break;
      default:
        setMenu("Home");
        break;
    }
  }, [location.pathname]);

  const WalletConnect = async () => {
    clearLocal();
    setShowConnectWallet(true);
  };

  const disConnectWallet = async () => {
    clearLocal();
    deactivate();
    toast.success("Disconnected successfully");
  };

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already

  // const onChangeNetworkClick = async () => {
  //   if (account)
  //     await window.ethereum.request({
  //       method: "wallet_switchEthereumChain",
  //       params: [{ chainId: "0x38" }],
  //     });
  // };
  // onChangeNetworkClick();

  return (
    <Fragment>
      <header id="header" className="header-two">
        <div className="site-navigation">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                  <div className="logo">
                    <NavLink to="/" className="d-block">
                      <img
                        loading="lazy"
                        src="images/main_logo.png"
                        alt="Constra"
                        className="header_logo"
                      />
                    </NavLink>
                  </div>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                    aria-controls="navbar-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    id="navbar-collapse"
                    className="collapse navbar-collapse"
                  >
                    <ul className="nav navbar-nav ml-auto align-items-center">
                      <li
                        className={
                          "nav-item " + (activeMenu === "Home" && "active")
                        }
                      >
                        <NavLink
                          exact
                          activeClassName="active"
                          to="/"
                          className="nav-link"
                        >
                          Home
                        </NavLink>
                      </li>
                      <li
                        className={
                          "nav-item " + (activeMenu === "Shop" && "active")
                        }
                      >
                        <NavLink
                          activeClassName="active"
                          to="/shop"
                          className="nav-link"
                        >
                          Shop
                        </NavLink>
                      </li>
                      <li
                        className={
                          "nav-item " +
                          (activeMenu === "Collections" && "active")
                        }
                      >
                        <NavLink
                          activeClassName="active"
                          to="/collections"
                          className="nav-link"
                        >
                          My MicroCats
                        </NavLink>
                      </li>
                      {/* <li
                        className={
                          "nav-item " + (activeMenu === "levelup" && "active")
                        }
                      >
                        <NavLink
                          activeClassName="active"
                          to="/levelup-rankup"
                          className="nav-link"
                        >
                          Level Up & Rank up
                        </NavLink>
                      </li> */}
                      {/* <li
                        className={
                          "nav-item " + (activeMenu === "create" && "active")
                        }
                      >
                        <NavLink
                          activeClassName="active"
                          to="/createNFT"
                          className="nav-link"
                        >
                          Create
                        </NavLink>
                      </li> */}
                      <li
                        className={
                          "nav-item " +
                          (activeMenu === "Marketplace" && "active")
                        }
                      >
                        <NavLink
                          activeClassName="active"
                          to="/marketplace"
                          className="nav-link"
                        >
                          Marketplace
                        </NavLink>
                      </li>
                      {/* <li
                        className={
                          "nav-item " + (activeMenu === "About" && "active")
                        }
                      >
                        <NavLink
                          activeClassName="active"
                          to="/about-us"
                          className="nav-link"
                        >
                          Team
                        </NavLink>
                      </li> */}

                      <div className="nav-item">
                        {account ? (
                          <a
                            className="nav-link nav-button"
                            onClick={() => disConnectWallet()}
                          >
                            Disconnect
                          </a>
                        ) : (
                          <a
                            className="nav-link nav-button"
                            onClick={() => WalletConnect()}
                          >
                            Connect Wallet
                          </a>
                        )}
                      </div>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <ConnectWallet
        setShowConnectWallet={setShowConnectWallet}
        isOpen={showConnectWallet}
      />
    </Fragment>
  );
};

export default Header;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

// import { Provider } from "react-redux";
// import store from "./redux/store/Store";

import { Web3ReactProvider } from "@web3-react/core";
import { ToastContainer } from "react-toastify";
import "../node_modules/react-toastify/dist/ReactToastify.css";

import getLibrary from "./utils/getLibrary";

ReactDOM.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
    <Web3ReactProvider getLibrary={getLibrary}>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <App />
      {/* </Provider> */}
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: "https://bsc-dataseed.binance.org",
      },
      network: "binance",
    },
  },
};

let web3 = null;
const useContract = (address = undefined, ABI) => {
  const wallet = localStorage.getItem("connectedWallet");
  const walletConnect = async () => {
    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
    });

    const provider = await web3Modal.connect();
    web3 = new Web3(provider);
  };

  if (wallet === "walletConnect") {
    walletConnect();
  } else {
    const { ethereum } = window;
    web3 = new Web3(ethereum);
  }
  // web3.eth.defaultAccount = account;
  window.web3 = web3;
  return useMemo(() => {
    if (!address || !ABI) return null;
    try {
      const contractInstance = new window.web3.eth.Contract(ABI, address);
      return contractInstance;
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [web3, address, ABI]);
};

export default useContract;

/* eslint-disable react-hooks/exhaustive-deps */
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import React, { useRef, useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import MetaMaskLogo from "./../../assets/images/metamask.svg";
import WalletConnectLogo from "./../../assets/images/walletconnect.svg";
import { injected, walletconnect } from "./../../utils/connectors";
import MetaMaskOnboarding from "@metamask/onboarding";
import { toast } from "react-toastify";
// import { useEagerConnect, useInactiveListener } from "./../../hooks";

const ConnectWallet = (props) => {
  const { setShowConnectWallet, isOpen } = props;
  const { account, activate, error, connector } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState();
  const onboarding = useRef();

  const onConnectWithMetamaskClick = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      setActivatingConnector(injected);
      activate(injected, undefined, true)
        .then(() => {
          toast.success("Connected successfully");
        })
        .catch(() => {
          // setTried(true);
        });
      localStorage.setItem("connectedWallet", "metamask");
    } else {
      onboarding.current.startOnboarding();
    }
  };

  const onConnectWithWalletConnect = () => {
    // if (connector && connector.walletConnectProvider?.wc?.uri) {
    //   connector.walletConnectProvider = undefined;
    // }
    setActivatingConnector(walletconnect);
    activate(walletconnect);
    localStorage.setItem("connectedWallet", "walletConnect");
  };

  // const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  // useInactiveListener(!triedEager || !!activatingConnector);

  // For Metamask OnBoarding
  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (account && account.length > 0) {
        onboarding.current.stopOnboarding();
      }
    }
    if (account) setShowConnectWallet(false);
  }, [account]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (account && account.length > 0) {
        onboarding.current.stopOnboarding();
      }
    }
  }, [account]);

  useEffect(() => {
    // console.log(error);
    if (error instanceof UnsupportedChainIdError) {
      toast.error("Please connect to the Binance Smart Chain");
    }
  }, [error]);

  return (
    <Modal
      closeModal={setShowConnectWallet}
      isOpen={isOpen}
      connectWallet={true}
    >
      <div className="sc-eCApGN cjAFRf web3modal-provider-wrapper">
        <div
          className="sc-hKFyIo jcNZzC web3modal-provider-container"
          onClick={onConnectWithMetamaskClick}
        >
          <div className="sc-bdnylx jMhaxE web3modal-provider-icon">
            <img src={MetaMaskLogo} alt="MetaMask" className="wallet_image" />
          </div>
          <div className="sc-gtssRu bktcUM web3modal-provider-name">
            MetaMask
          </div>
          <div className="sc-dlnjPT eFHlqH web3modal-provider-description">
            Connect to your MetaMask Wallet
          </div>
        </div>
      </div>
      <div
        className="sc-eCApGN cjAFRf web3modal-provider-wrapper"
        style={{
          borderTop: "1px solid rgba(195, 195, 195, 0.56)",
        }}
      >
        <div
          className="sc-hKFyIo jcNZzC web3modal-provider-container"
          onClick={onConnectWithWalletConnect}
        >
          <div className="sc-bdnylx jMhaxE web3modal-provider-icon">
            <img src={WalletConnectLogo} alt="WalletConnect" className="wallet_image" />
          </div>
          <div className="sc-gtssRu bktcUM web3modal-provider-name">
            WalletConnect
          </div>
          <div className="sc-dlnjPT eFHlqH web3modal-provider-description">
            Scan with WalletConnect to connect
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectWallet;

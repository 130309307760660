import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

// const POLLING_INTERVAL = process.env.REACT_APP_POLLING_INTERVAL;

// const RPC_URLS = {
//   56: "https://bsc-dataseed.binance.org",
// };

// Add different connectors
export const injected = new InjectedConnector({
  // supportedChainIds: [1, 3, 4, 5, 42, 56], // Change according to supported Network Ids
  supportedChainIds: [56, 97], // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
});
// For main
export const walletconnect = new WalletConnectConnector({
  rpc: { 56: "https://bsc-dataseed.binance.org" },
  bridge: "https://bridge.walletconnect.org",
  chainId: 56,
  qrcode: true,
  pollingInterval: 12000,
});
// For Testnet
// export const walletconnect = new WalletConnectConnector({
//   rpc: { 97: "https://data-seed-prebsc-1-s1.binance.org:8545/" },
//   chainId: 97,
//   qrcode: true,
//   pollingInterval: 12000,
// });

import React from "react";
export const guestRoutes = [
  {
    path: "/collections",
    exact: true,
    name: "collections",
    component: React.lazy(() => import("../../views/Collection")),
  },
  {
    path: "/shop",
    exact: true,
    name: "shop",
    component: React.lazy(() => import("../../views/Shop")),
  },
  // {
  //   path: "/levelup-rankup",
  //   exact: true,
  //   name: "LevelRankUP",
  //   component: React.lazy(() => import("../../views/LevelRankUP/LevelRankUP")),
  // },
  {
    path: "/marketplace",
    exact: true,
    name: "marketplace",
    component: React.lazy(() => import("../../views/Marketplace")),
  },
  {
    path: "/about-us",
    exact: true,
    name: "about-us",
    component: React.lazy(() => import("../../views/AboutUs")),
  },
  // {
  //   path: "/createNFT",
  //   exact: true,
  //   name: "createNFT",
  //   component: React.lazy(() => import("../../views/CreateNFT/CreateNFT")),
  // },
  {
    path: "/",
    name: "Home",
    exact: true,
    component: React.lazy(() => import("../../views/Home")),
  },

  // Main route's default dashboard
  // {
  //   redirectRoute: true,
  //   name: "homeRedirect",
  //   path: "/",
  // },
];

// import RoutesComp from "./components/routes/Routes";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import { guestRoutes } from "./routes";
import React from "react";
import { Spinner } from "react-bootstrap";
import "./App.css";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useRef } from "react";
import { useEffect } from "react";
import MetaMaskOnboarding from "@metamask/onboarding";
import { useEagerConnect, useInactiveListener } from "./hooks";

function App() {
  const { account, connector } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState();
  const onboarding = useRef();

  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  // For Metamask OnBoarding
  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (account && account.length > 0) {
        onboarding.current.stopOnboarding();
      }
    }
  }, [account]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (account && account.length > 0) {
        onboarding.current.stopOnboarding();
      }
    }
    // if (account && account.length > 0) {
    //   toast.success("Connected successfully");
    // }
  }, [account]);

  // useEffect(() => {
  //   if (error instanceof UnsupportedChainIdError) {
  //     toast.error("Please connect to the appropriate Ethereum network.");
  //   }
  // }, [error]);

  let mainContent = (
    <>
      {guestRoutes.map((route) =>
        route.component ? (
          <Route
            key={route.name}
            path={route.path}
            exact={route.exact}
            name={route.name}
          >
            <route.component />
          </Route>
        ) : (
          route.redirectRoute && <Redirect key={route.name} to={route.path} />
        )
      )}
    </>
  );
  return (
    <React.Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <Header />
        <div className="footer-adjust">
          <Switch>{mainContent}</Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </React.Suspense>
    // <div className="body-inner">
    //   <BrowserRouter>
    //     <Switch>{mainContent}</Switch>
    //     {/* <RoutesComp /> */}
    //   </BrowserRouter>
    // </div>
  );
}

export default App;
